export default {
  heading: 'Oswald, sans-serif',
  body: 'Oswald, sans-serif',
  monospace: 'Menlo, monospace',
  display: 'Pacifico',
  googleFonts: [
    'Oswald:100,200,300,400,500,600,700,800,900',
    'Pacifico'
    // 'Arvo',
    // 'Asap',
  ]
  // customFamilies: ['Din'],
  // customUrls: ['https://www.gonation.biz/fonts/din/din.css'],
}
